/** @jsxRuntime classic */
/** @jsx h */
import {
  AutocompleteComponents,
  AutocompletePlugin,
  getAlgoliaResults,
} from '@algolia/autocomplete-js';
import { h } from 'preact';

import { ALGOLIA_CATEGORIES_INDEX_NAME, LOCALE, applicationTranslation, DEBOUNCE_TIME } from '../constants';
import { searchClient } from '../searchClient';
import { PopularCategoryHit } from '../types';
import { cx } from '../utils';
import { debouncePromise } from '../utils/debounce';
import { RequesterDescription } from '@algolia/autocomplete-shared/dist/esm/preset-algolia/createRequester';

const debounced = debouncePromise<RequesterDescription<PopularCategoryHit>>((a) => getAlgoliaResults(a), DEBOUNCE_TIME);

export const popularCategoriesPlugin: AutocompletePlugin<PopularCategoryHit, {}> = {
  getSources() {
    return [
      {
        sourceId: 'popularCategoriesPlugin',
        getItems() {
          return debounced({
            searchClient,
            queries: [
              {
                indexName: ALGOLIA_CATEGORIES_INDEX_NAME,
                params: {
                  hitsPerPage: 4,
                  filters: 'hasImage',
                },
              },
            ],
          });
        },
        getItemInputValue({ item }) {
          return item.name;
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          header({ Fragment }) {
            return (
              <Fragment>
                <span className="aa-SourceHeaderTitle">{applicationTranslation[LOCALE]['app.ui.popular.categories']}</span>
                {/* <div className="aa-SourceHeaderLine" /> */}
              </Fragment>
            );
          },
          item({ item, components }) {
            return <CategoryItem hit={item} components={components} />;
          },
        },
      },
    ];
  },
};

type CategoryItemProps = {
  hit: PopularCategoryHit;
  components: AutocompleteComponents;
};

function CategoryItem({ hit }: CategoryItemProps) {
  return (
  <a
  href={`/${applicationTranslation[LOCALE]['app.link.activities']}/${hit.slug}`}
  className={cx(
    'aa-ItemLink aa-QuickAccessItem',
    `aa-QuickAccessItem--sales-banner`
  )}>
  <div className="aa-ItemContent">
    {hit.thumbnail && (
      <div className="aa-ItemPicture aa-Category">
        <img src={`/uploads/images/categories/${hit.thumbnail}`} alt={hit.name} />
      </div>
    )}

    <div className="aa-ItemContentBody">
      <div className="aa-ItemContentTitle">
        {hit.name} ({hit.productCount})
      </div>
    </div>
  </div>
</a>
  );
}
