import { addHighlightedAttribute } from './addHighlightedAttribute';
export function search(_ref) {
  var query = _ref.query,
    items = _ref.items,
    limit = _ref.limit;
  if (!query) {
    return items.slice(0, limit).map(function (item) {
      return addHighlightedAttribute({
        item: item,
        query: query
      });
    });
  }
  return items.filter(function (item) {
    return item.label.toLowerCase().includes(query.toLowerCase());
  }).slice(0, limit).map(function (item) {
    return addHighlightedAttribute({
      item: item,
      query: query
    });
  });
}