export const LOCALE = document.documentElement.lang;
export const DEBOUNCE_TIME = 600;
export const ALGOLIA_PRODUCTS_INDEX_NAME = `products_${LOCALE}`;
export const ALGOLIA_CATEGORIES_INDEX_NAME = `categories_${LOCALE}`;
export const ALGOLIA_CITIES_INDEX_NAME = "cities";
export const ALGOLIA_PRODUCTS_QUERY_SUGGESTIONS_INDEX_NAME = `products_${LOCALE}_query_suggestions`;
export const applicationTranslation = {
  en: {
    "app.link.activities": "activities",
    "app.ui.quick.access": "Quick access",
    "app.ui.all.activities": "All activities",
    "app.ui.productsfor": "Our selection",
    "app.ui.popular.searches": "Popular searches",
    "app.ui.popular.categories": "Popular categories",
    "app.ui.search.placeholder": "Search an activity, a city, a category",
    "app.ui.search.mobile.placeholder": "Activity, city, category",
    "app.ui.noresult": "No results for",
    "app.ui.recentsearches": "Recent searches",
    "app.ui.suggestions": "Search suggestions",
    "app.ui.noresult.advices.1": "Double-check your spelling",
    "app.ui.noresult.advices.2": "Use fewer keywords",
    "app.ui.noresult.advices.3": "Search for a less specific item",
    "app.ui.noresult.advices.4":
      "Try navigate using on the of the popular categories",
    "app.ui.search.cancel": "Cancel",
  },
  fr: {
    "app.ui.quick.access": "Accès rapide",
    "app.ui.all.activities": "Toutes les activités",
    "app.link.activities": "activités",
    "app.ui.productsfor": "Notre sélection",
    "app.ui.popular.searches": "Recherches populaires",
    "app.ui.popular.categories": "Catégories populaires",
    "app.ui.search.placeholder":
      "Rechercher une activité, une ville, une catégorie",
    "app.ui.search.mobile.placeholder": "Activité, ville, catégorie",
    "app.ui.noresult": "Aucun résultat pour",
    "app.ui.recentsearches": "Recherches récentes",
    "app.ui.suggestions": "Suggestions de recherche",
    "app.ui.categories": "Catégories",
    "app.ui.cities": "Villes",
    "app.ui.sites": "Sites",
    "app.ui.noresult.advices.1": "Vérifiez votre orthographe",
    "app.ui.noresult.advices.2": "Utiliser moins de mots-clés",
    "app.ui.noresult.advices.3": "Recherche d'un élément moins spécifique",
    "app.ui.noresult.advices.4":
      "Essayez de naviguer en utilisant l'une des catégories populaires",
    "app.ui.search.cancel": "Retour",
  },
  es: {
    "app.ui.quick.access": "Acceso rápido",
    "app.ui.all.activities": "Todas las actividades",
    "app.link.activities": "actividades",
    "app.ui.productsfor": "Nuestra selección",
    "app.ui.popular.searches": "Búsquedas populares",
    "app.ui.popular.categories": "Categorías populares",
    "app.ui.search.placeholder":
      "Buscar una actividad, una ciudad, una categoría",
    "app.ui.search.mobile.placeholder": "Actividad, ciudad, categoría",
    "app.ui.noresult": "Sin resultados para",
    "app.ui.recentsearches": "Búsquedas recientes",
    "app.ui.suggestions": "Sugerencias de búsqueda",
    "app.ui.categories": "Categorías",
    "app.ui.cities": "Ciudades",
    "app.ui.sites": "Sitios",
    "app.ui.noresult.advices.1": "Verifica tu ortografía",
    "app.ui.noresult.advices.2": "Usa menos palabras clave",
    "app.ui.noresult.advices.3": "Busca un elemento menos específico",
    "app.ui.noresult.advices.4":
      "Intenta navegar usando una de las categorías populares",
    "app.ui.search.cancel": "Cancelar",
  },
  de: {
    "app.ui.quick.access": "Schnellzugriff",
    "app.ui.all.activities": "Alle Aktivitäten",
    "app.link.activities": "Aktivitäten",
    "app.ui.productsfor": "Unsere Auswahl",
    "app.ui.popular.searches": "Beliebte Suchanfragen",
    "app.ui.popular.categories": "Beliebte Kategorien",
    "app.ui.search.placeholder":
      "Suche nach einer Aktivität, einer Stadt, einer Kategorie",
    "app.ui.search.mobile.placeholder": "Aktivität, Stadt, Kategorie",
    "app.ui.noresult": "Keine Ergebnisse für",
    "app.ui.recentsearches": "Letzte Suchanfragen",
    "app.ui.suggestions": "Suchvorschläge",
    "app.ui.categories": "Kategorien",
    "app.ui.cities": "Städte",
    "app.ui.sites": "Seiten",
    "app.ui.noresult.advices.1": "Überprüfen Sie Ihre Rechtschreibung",
    "app.ui.noresult.advices.2": "Verwenden Sie weniger Schlüsselwörter",
    "app.ui.noresult.advices.3":
      "Suchen Sie nach einem weniger spezifischen Element",
    "app.ui.noresult.advices.4":
      "Versuchen Sie, eine der beliebten Kategorien zu verwenden",
    "app.ui.search.cancel": "Abbrechen",
  },
};
