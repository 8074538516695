export function createStorageApi(storage) {
  return {
    addItem: function addItem(item) {
      storage.onRemove(item.id);
      storage.onAdd(item);
    },
    removeItem: function removeItem(id) {
      storage.onRemove(id);
    },
    getAll: function getAll(query) {
      return storage.getAll(query);
    }
  };
}